#cases_container {
    %flex-column-display {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .header-part {
        @extend %flex-column-display;
        justify-content: space-between;
        margin-bottom: 1em;

        h2 {
            font-size: 22px;
            font-weight: 700;
        }

        .main-btn {
            padding: 0.5em 1.5em;
            text-transform: capitalize;
            font-size: 14px;
        }
    }

    .search-filter {
        @extend %flex-column-display;
        justify-content: space-between;
        margin-bottom: 2em;

        input {
            width: 300px;
            padding: 0.5em;
            font-size: 14px;
            border: 1px solid rgba(0,0,0,0.09);
            border-radius: 8px;
        }

        .filter {
            @extend %flex-column-display;
            column-gap: 1em ;

            button {
                display: inline-block;
                background-color: $light-grey;
                border: 0;
                padding: 0.5em;
                font-size: 12px;
                border-radius: 8px;
            }

            .viewing-opt {
                font-size: 14px;
                color: $text-color;

                select {
                    padding: 0.5em;
                    outline: none;
                    border: 0;
                    color: $black-color;
                }
            }
        }
    }

    .cases-list-container {
        
    }

    #create_new_case {
        h4 {
            margin-top: 0;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 1em;
            
        }

        .inputs-container {
            margin-bottom: 1.5em;
            padding-bottom: 2em;
            border-bottom: 1px solid rgba(0,0,0,0.03);

            label {
                margin-bottom: 0.3em;
            }

            .case-summary {
                display: block;
                width: 100%;
                padding: 0.85em;
                font-weight: 600;
                border-radius: 5px;
                border: 1px solid #e2e2e2;
                margin-bottom: 1em;
            }

            .ql-editor {
                min-height: 200px;
                max-height: 500px;
            }
        }

        .btn-container {
            display: flex;
            justify-content: flex-end;
            column-gap: 1em;

            .cancel {
                background-color: transparent;
                padding: 0.5em 1em;
                outline: none;
                border: 0;
            }
        }
    }

    #single_case_view {

        .header-part {
            margin-bottom: 2em;
        }

        .action-btns {
            display: flex;
            flex-direction: row;
            column-gap: 1.8em;
            align-items: center;

            .more-btns {
                button {
                    border: 1px solid rgba(0,0,0,0.05);
                    border-right: 0;
                    padding: 0.3em 0.75em;
                    font-size: 14px;
                    background-color: $light-grey;

                    &:last-child {
                        border-right: 1px solid rgba(0,0,0,0.05);
                    }

                    &:hover {
                        background-color: #b3b3b3;
                    }
                }
            }
        }

        .single-case {
            .case-header {
                margin-bottom: 1em;
                h4 {
                    margin: 0;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 0.2em;
                }

                .header-footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        font-size: 14px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-weight: 300;
    
                        .v-separator {
                            content: "";
                            display: inline-block;
                            width: 1px;
                            height: 15px;
                            background-color: $text-color;
                            margin: 0 1em;
                        }
                    }

                    .reply-button {
                        font-size: 14px;
                        border: 0;
                        border: 0;
                        background-color: $primary-color;
                        font-weight: 300;
                        border-radius: 20px;
                        color: $white-color;
                        padding: 0.5em 1em;
                    }
                }
            }
            .single-case-tab {
                display: flex;
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                flex-direction: row;
                column-gap: 1em;
                width: 100%;
                border-bottom: 2px solid rgba(0,0,0,0.06);
                margin-bottom: 2em;
    
                .tab {
                    padding: 0.75em 0.75em;
                    font-size: 16px;
                    font-weight: 300;
                    cursor: pointer;
    
                    &.active {
                        color: $primary-color;
                        border-bottom: 2px solid $primary-color;
                    }
                }
            }

            .content-container {
                .conversation-tab {
                    padding: 1em 1em 1em 3em;

                    .timeline-container {
                        border-left: 2px solid rgba(0,0,0,0.3);
                        padding: 20px 10px 10px 40px;
                        margin-bottom: 2em;
                    }

                    .comment {
                        border: 1px solid rgba(0,0,0,0.09);
                        margin-bottom: 40px;
                        border-radius: 8px;
                        background-color: $light-grey;
                        padding: 0.5em;
                        position: relative;

                        .comment-header{
                            padding-bottom: 0.5em;
                            border-radius: 8px 8px 0 0;
                            
                            p {
                                margin: 0;
                                padding: 0;
                            }
                        }

                        .comment-content{
                            padding: 1em;
                            background-color: $white-color;
                            border: 1px solid rgba(0,0,0,0.05);
                        }

                        .timeline-indicator {
                            position: absolute;
                            top: -30px;
                            left: -82px;
                            display: flex;
                            flex-direction: column;
                            justify-items: center;
                            text-align: center;

                            .comm-type {
                                background-color: #e2e2e2;
                                font-size: 11px;
                                border-radius: 5px;
                                padding: 0.3em;
                                width: 80px;
                                text-align: center;
                                margin-bottom: 0.5em;
                                color: $white-color;
                            }

                            .circle {
                                display: inline-flex;
                                width: 30px;
                                height: 30px;
                                font-size: 14px;
                                margin: 0 auto;
                                background-color: lighten($red-color, 30%);
                                justify-content: center;
                                align-items: center;
                                border-radius: 15px;
                                color: rgba(0,0,0,0.6);
                            }
                        }
                    }

                    .comment-sec {
                        padding: 1em;
                        border: 1px solid rgba(0,0,0,0.05);
                        border-radius: 8px;

                        h4 {
                            font-size: 16px;
                            margin: 0;
                            margin-bottom: 1em;
                            font-weight: 600;
                        }

                        .ql-editor {
                            min-height: 200px;
                            max-height: 500px;
                            margin-bottom: 1em;
                        }

                        h5 {
                            font-size: 14px;
                            margin-top: 2em;
                            margin-bottom: 0.5em;
                        }

                        .btns-container {
                            text-align: center;
                            margin-top: 2em;

                            .main-btn {
                                display: inline-block;
                                padding: 0.5em 1em;
                                text-transform: initial;
                            }
                        }
                    }
                }

                .details-tab {
                    h5 {
                        font-size: 16px;
                    }

                    .details-container {
                        padding-left: 1em;
                        padding-right: 1em;
                        padding-bottom: 1.5em;
                        margin-bottom: 1.5em;
                        border-bottom: 1px solid rgba(0,0,0,0.05);

                        &:last-child {
                            border-bottom: 0;
                        }

                        table {
                            width: 100%;
                            border-collapse: separate;
                            border-spacing: 1em 0.5em;
                            tr {
                                td {
                                    font-size: 16px;
                                    color: $black-color;
                                    text-transform: capitalize;
                                    &:first-child {
                                        text-align: right;
                                        width: 40%;
                                        color: $text-color;
                                    }
                                }
                            }
                        }

                        .file-item {
                            background-color: $white-color;
                            border: 1px solid $light;
                            padding: 0.3em 0.5em;
                            border-radius: 5px;
                            font-size: 14px;
                            font-weight: 300;
                            display: flex;
                            position: relative;
                            width: 40%;
                  
                            .file-name {
                              white-space: nowrap;
                              overflow: hidden;
                              display: inline-block;
                              color: $primary-color;
                            }
                  
                            .file-size {
                              display: inline-block;
                              color: $text-color;
                            }
                  
                            .remove-btn {
                              position: absolute;
                              right: 0;
                              top: 0;
                              padding: 0 0.65em;
                              border: 0;
                              background-color: #e6e6e6;
                              border-radius: 0 5px 5px 0;
                              display: none;
                              transition: $transition;
                              font-size: 16px;
                              font-weight: 500;
                              height: 100%;
                              color: $text-color;
                            }
                  
                            &:hover {
                              .remove-btn {
                                display: block;
                                transition: $transition;
                              }
                            }
                          }
                    }
                }

                .resolution-tab {
                    h5 {
                       font-size: 16px; 
                    }

                    form {
                        .ql-editor {
                            min-height: 200px;
                            max-height: 500px;
                            margin-bottom: 1em;
                        }

                        .attachments-header {
                            margin-top: 2em;
                        }

                        .btns-container {
                            text-align: center;
                            margin-top: 2em;
                            margin-bottom: 2em;

                            .main-btn {
                                display: inline-block;
                                padding: 0.5em 1em;
                                text-transform: initial;
                            }
                        }
                    }
                }
            }
        }
        
    }
}