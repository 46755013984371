#welcome_page {
    .welcome-centent {
        .hero-part {
            height: 100vh;
            width: 100vw;
            padding: 0 8em;
            padding-top: 12em;
            text-align: center;

            h1 {
                font-size: 50px;
                text-transform: capitalize;
                margin: 0;
                margin-bottom: 0.5em;

                span {
                    background-color: lighten($primary-color, 40%);
                    padding: 0 0.3em;
                    border-radius: 50px;
                }
            }

            p {
                font-size: 16px;
                color: $text-color;
                width: 50%;
                margin: 0 auto;
            }

            .main-btn {
                margin: 2.5em auto;
                padding: 1em 2em;
                background-color: $black-color !important;
                color: $white;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 0.5em;
                }
            }
        }
    }
}