#chats {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: calc(100vh - 9em);
    column-gap: 1em;

    .inner-container {
        height: calc(100vh - 13.5em) !important;
    }

    .chat-history {
        .header-part {
            height: 3.5em;
            display: flex;
            align-items: center;
            padding: 0.5em 0.2em;

            h2 {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
            }
        }

        .action-btn {
            // padding: 0 0.3em;s
            height: 3em;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            column-gap: 1em;

            button {
                background-color: transparent;
                width: 100%;
                border-radius: 30px;
                border: 0;
                transition: $transition;
                color: $text-color;

                &.active-opt {
                    background-color: $primary-color;
                    color: $white-color;
                    transition: $transition;

                    &:hover {
                        background-color: $primary-color;
                    }
                }

                &:hover {
                    background-color: rgba(0,0,0,0.05);
                }
            }
        }

        .history-container {
            height: calc(100vh - 15.5em);
            padding: 0.5em 0;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .history-item {
                padding: 0.5em 0.75em;
                display: grid;
                grid-template-columns: 1fr 5fr auto;
                justify-content: flex-start;
                align-items: center;
                border-radius: 8px;
                transition: $transition;
                margin-bottom: 0.3em;
                cursor: pointer;

                .profile-img {
                    border-radius: 25px;
                    background-color: rgba(0,0,0,0.6);
                    height: 45px;
                    width: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    color: $white-color;
                }
                .message-and-name {
                    .name {
                        font-size: 14px;
                        font-weight: 600;
                        color: $black-color;
                        margin: 0;
                    }

                    .last-message {
                        font-size: 14px;
                        margin: 0;
                        color:rgba(0,0,0, 0.6);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 220px;
                    }
                }
                
                .time-and-badge {
                    align-self: flex-start;

                    .time {
                        font-size: 14px;
                        color: rgba(0,0,0,0.5);
                    }
                }

                &:hover {
                    background-color: rgba(0,0,0,0.09);
                    transition: $transition;
                }

                &.active-chat {
                    background-color: lighten($primary-color, 20%);

                    .name {
                        color: $white-color;
                    }

                    .last-message {
                        color: rgba(255, 255, 255, 0.6);
                    }

                    .time {
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }

    .message-view {
        background-color: $light-grey;
        border-radius: 8px;
        box-shadow: inset 0 0 10px rgba(0,0,0,0.19);
        // padding: 0 1.5em;
        position: relative;
        // padding-top: 6em;

        .inner-container {
            overflow-y: auto;
            display: flex;
            flex-direction: column-reverse;
            background-color: transparent;
            height: calc(100vh - 15em);
            padding: 0 1.5em;
            border: 0;
            border-radius: 0;

            &::-webkit-scrollbar {
                display: none;
            }
            
        }

        .header-part {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 8px 8px 0 0;
            background-color: rgba(255, 255, 255, 0.5);
            -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
            // box-shadow: $box-shadow;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 1em;
            align-items: center;

            h4 {
                margin: 0;
                font-size: 18px;
                font-weight: 500;
            }

            .action-btn {
                display: flex;
                flex-direction: row;
                column-gap: 1em;
                align-items: center;

                button {
                    background-color: transparent;
                    
                    &.attend-chat {
                        padding: 0.5em 0.75em;
                        border-radius: 10px;
                        border: 1px solid $primary-color;
                        color: $primary-color;
                        background-color: $white-color;
                        transition: $transition;

                        &:hover {
                            background-color: $primary-color;
                            color: $white-color;
                        }
                    }

                    &.more-options {
                        border: 0;
                        color: $black-color;
                    }
                }
            }
        }

        .messages {
            padding-top: 5em;
            .message-container {
                display: grid;
                margin-bottom: 0.5em;

                .message-wrapper {
                    .message {
                        padding: 0.5em 1em;
                        display: inline-block;
                    }

                    &.received {
                        .message {
                            max-width: 57%;
                            background-color: $white-color;
                            border: 1px solid rgba(0,0,0,0.09);
                            border-radius: 20px;
                        }
                        .time {
                            display: block;
                            margin-left: 1em;
                            font-size: 12px;
                            color: $text-color;
                            margin-top: 0.3em;
                        }
                    }

                    &.outgoing {
                        display: flex;
                        flex-direction: column;
                        justify-self: flex-end;
                        
                        .message {
                            max-width: 57%;
                            margin-left: auto;
                            background-color: $primary-color;
                            border-radius: 20px;
                            color: white;
                        }
                        .time {
                            text-align: right;
                            display: block;
                            margin-right: 1em;
                            font-size: 12px;
                            color: $text-color;
                            margin-top: 0.3em;
                        }
                    }

                }
            }
        }
    }

    .write-message-container {
        padding: 0 2em;
        width: 100%;
        // background-color: rebeccapurple;
        // min-height: 3.5em;
        
        .write-message {
            display: grid;
            grid-template-columns: 4fr auto; 
            column-gap: 1em;
            align-items: center;
            box-shadow: $box-shadow;
            background-color: $white;
            border-radius: 8px;
            padding-right: 1em ;

            textarea {
                padding: 0.5em;
                margin: 0;
                min-height: 40px;
                max-height: 50px;
                resize: none;
                border: 0;
                background-color: transparent;
                outline: 0;
            }

            .action-btns {
                display: flex;
                flex-direction: row;
                column-gap: 0.75em;
                align-items: center;
                

                button {
                    border: 0;
                    padding: 0;
                    font-size: 10px;
                    background-color: transparent;
                    color: $text-color;

                    &.send-btn {
                        background-color: $primary-color;
                        color: $white-color;
                        border-radius: 8px;
                        padding: 0.5em;
                    }
                }

                .v-divider {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 12px;
                    background-color: $text-color;
                }
            }
        }
    }
}